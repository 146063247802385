import {
    Box,
    Button,
    Card,
    CardBody,
    Center,
    CircularProgress,
    Fade,
    Heading,
    HStack,
    Image,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useRef, useState} from "react";
import {useFetch} from "../hooks/useFetch";
import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {HTTPErrorCodeBookingInvalidOrExpired} from "../common/constants";
import {BookingInvalidStateAlert} from "./common/BookingInvalidStateAlert";
import {validateState} from "../helpers/states_validation";
import {useTranslation} from "react-i18next";
import logo from "../assets/img/lockers.webp";

const checkBranchOpeningURL = "/branch/opening_check"
const openBranchURL = "/branch/open"

export const OpenBranchBox = ({
                                  cardRef = {} as React.RefObject<HTMLDivElement>,
                                  registerRef = {} as React.RefObject<HTMLDivElement>,
                                  height = [500]
                              }) => {
    const registerHeadingRef = useRef(null);
    const registerBoxRef = useRef(null);
    const {t: t, i18n: {language}} = useTranslation('common');
    const toast = useToast()
    const [errors, setErrors] =
        useState<Map<string, string>>(new Map());
    const {bookingID} = useParams();

    const {
        data: booking,
        fetch: fetchValidateBooking,
        isLoading: isValidatingBooking
    } = useFetch()

    const {
        fetch: fetchOpenBranch,
        isLoading: isLoading
    } = useFetch()

    // Scroll to the section when button is clicked
    const scrollToSection = (ref: any) => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };

    useEffect(() => {
        scrollToSection(registerRef)
        validateBooking()
    }, []);

    const validateBooking = async () => {
        const error = await fetchValidateBooking("get", checkBranchOpeningURL + "/" + bookingID, {})

        setErrors(validateState(error?.response?.data?.Code, language))
    }

    const handleConfirmOpening = async () => {
        const error = await fetchOpenBranch("post", openBranchURL, booking)


        if (error?.response?.data?.Code === HTTPErrorCodeBookingInvalidOrExpired) {
            toast({
                title: t('Errors.InvalidOrExpiredBooking'),
                description: t('Errors.IfNecessaryPleaseContactUs'),
                status: 'error',
                duration: 5000,
                isClosable: false,
            })

            return
        }

        if (error instanceof Error) {
            toast({
                title: t('Errors.OpeningBranchError'),
                description: t('Errors.PleaseContactUs'),
                status: 'error',
                duration: 5000,
                isClosable: false,
            })

            return
        }
    }

    return (
        <Box ref={registerBoxRef} id={'payment'} position="relative" color={'lightBrand'}
             height={height} w={'100%'} transition="height 0.5s ease">
            <Center w={'100%'}>
                <Image draggable={false} src={logo} w={'100%'}
                       height={height} objectFit={'cover'}/>
            </Center>
            <Box
                position="absolute"
                top={0}
                left={0}
                width={'100%'}
                height={'100%'}
                opacity={0.8} bgColor={'primary.500'}
            />

            <Fade in={true} transition={{enter: {duration: 0.5}}}>
                <VStack
                    position={'absolute'}
                    top={500}
                    left="50%"
                    transform="translate(-50%, -50%)"
                    textAlign="center"
                    w={['90%', '70%', '50%', '40%', '30%']}
                    justify={'center'}
                    m={'auto'}>
                    <Heading ref={registerHeadingRef} textAlign={'center'} fontSize={[30, 36, 44]} mb={4}
                             textTransform='uppercase'>
                        {t('OpenBranchTitle')}
                    </Heading>
                    <VStack w={'100%'} id={'pay_vstack'} h={height}>
                        {isValidatingBooking ?
                            <CircularProgress isIndeterminate color={'primary.500'}/>
                            :
                            errors.size !== 0 && errors.has(HTTPErrorCodeBookingInvalidOrExpired) ?
                                <BookingInvalidStateAlert errors={errors}/>
                                :
                                <VStack justify={'left'} width='100%'>
                                    <HStack w={'100%'} justifyContent={'center'} position={'relative'} bottom={0}>
                                        <Button w={'100%'} as={motion.button} whileHover={{scale: 1.05}}
                                                _hover={{bgColor: 'secondary.500'}}
                                                bg={'secondary.500'} color={'lightBrand'}
                                                onClick={handleConfirmOpening}
                                                isLoading={isLoading}>
                                            {t('OpenBranch')}
                                        </Button>
                                    </HStack>
                                </VStack>
                        }
                    </VStack>
                </VStack>
            </Fade>
        </Box>

    )
}