import {Navigate, Route, Routes} from "react-router-dom";
import {Main} from "../pages/Main";
import {Rent} from "../pages/Rent";
import {Pay} from "../pages/Pay";
import {Extend} from "../pages/Extend";
import {PayExceededAndExtendTimePathname} from "../common/constants";
import {Exceeded} from "../pages/Exceeded";
import {Penalty} from "../pages/Penalty";
import {OpenBranchDoor} from "../pages/OpenBranchDoor";

export const Router = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Main/>}/>
            <Route path={'/rent'} element={<Rent/>}/>
            <Route path={'/pay/:bookingID'} element={<Pay/>}/>
            <Route path={'/extend/:bookingID'} element={<Extend/>}/>
            <Route path={`/${PayExceededAndExtendTimePathname}/:bookingID`} element={<Extend/>}/>
            <Route path={'/pay_exceeded/:bookingID'} element={<Exceeded/>}/>
            <Route path={'/pay_penalty/:bookingID'} element={<Penalty/>}/>
            <Route path={'/open_branch/:bookingID'} element={<OpenBranchDoor/>}/>

            <Route path={'*'} element={<Navigate to={'/'}/>}/>
        </Routes>
    );
}