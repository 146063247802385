import {Header} from "../components/common/Header";
import {Footer} from "../components/common/Footer";
import {OpenBranchBox} from "../components/OpenBranchBox";

export const OpenBranchDoor = () => {
    return (
        <>
            <Header/>
            <OpenBranchBox/>
            <Footer/>
        </>
    )
}